import React from "react";
import { render } from "react-dom";
import "./index.css";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import { SnackbarProvider } from "notistack";

const root = document.getElementById("root");
render(
  <SnackbarProvider maxSnack={1}>
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </Router>
  </SnackbarProvider>,
  root
);
