import React from "react";
import ExcelJS from "exceljs";
import { RiFileExcel2Line } from "react-icons/ri";
import axios from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useSnackbar } from "notistack";

dayjs.extend(utc);
dayjs.extend(timezone);

function DownloadExcel() {
  const { enqueueSnackbar } = useSnackbar();
  const handleDownload = async () => {
    try {
      const requestPayload = await axios.get(
        "https://pln-backend.vercel.app/v2/api/reports",
        { headers: { "Cache-Control": "no-store" } }
      );

      const responsePayload = requestPayload.data;
      if (responsePayload.status_info) {
        const data = responsePayload.data;

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Sheet 1");
        worksheet.properties.defaultRowHeight = 15;
        worksheet.getColumn("A").width = 20;
        worksheet.getColumn("B").width = 20;
        worksheet.getColumn("C").width = 20;
        worksheet.getColumn("D").width = 10;
        worksheet.getColumn("E").width = 10; // pekerjaan
        worksheet.getColumn("F").width = 15;
        worksheet.getColumn("G").width = 10;
        worksheet.getColumn("H").width = 20;
        worksheet.getColumn("I").width = 15;
        worksheet.getColumn("J").width = 15;
        worksheet.getColumn("K").width = 15;
        worksheet.getColumn("L").width = 50;
        worksheet.getColumn("M").width = 30;

        // Header row
        const row1 = worksheet.addRow([
          "No",
          "Tanggal",
          "Vendor",
          "Jam",
          "Nama Pekerjaan",
          "",
          "",
          "",
          "",
          "",
          "",
          "Deskription",
          "Lokasi",
        ]);
        const row2 = worksheet.addRow([
          " ",
          " ",
          " ",
          " ",
          "JTM (Kms)",
          "Gardu (Unit)",
          "JTR (Kms)",
          "SR/APP (Pelanggan)",
          "TIANG BETON",
          "",
          "",
          "",
          "",
        ]);
        const row3 = worksheet.addRow([
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "9 Meter (BTG)",
          "12 Meter (BTG)",
          "13 Meter (BTG)",
          "",
          "",
        ]);
        row1.font = { bold: true };
        row2.font = { bold: true };
        row3.font = { bold: true };
        [row1, row2, row3].forEach((row) => {
          row.eachCell({ includeEmpty: true }, (cell) => {
            cell.alignment = {
              horizontal: "center",
              vertical: "center",
              wrapText: true,
            };
          });
        });
        worksheet.mergeCells(`A1:A3`);
        worksheet.mergeCells(`B1:B3`);
        worksheet.mergeCells(`C1:C3`);
        worksheet.mergeCells(`D1:D3`);
        worksheet.mergeCells(`E2:E3`);
        worksheet.mergeCells(`F2:F3`);
        worksheet.mergeCells(`G2:G3`);
        worksheet.mergeCells(`E1:K1`);
        worksheet.mergeCells(`I2:K2`);
        worksheet.mergeCells(`L1:L3`);
        worksheet.mergeCells(`M1:M3`);

        // Data rows
        const excelData = [];
        let totalJTM = 0;
        let totalGardu = 0;
        let totalJTR = 0;
        let totalSRAPP = 0;
        let total9Meter = 0;
        let total12Meter = 0;
        let total13Meter = 0;
        let rowCounter = 1;

        data.forEach((entry, index) => {
          const datas = entry["users"];

          datas.forEach((user) => {
            const createdBy = user["createdBy"];
            const reports = user["reports"];

            reports.forEach((report) => {
              const tiangBeton = report["Nama Pekerjaan"]["TIANG BETON"];

              worksheet.addRow([
                rowCounter++,
                entry.Date,
                createdBy,
                report["Jam"],
                report["Nama Pekerjaan"]["JTM (Kms)"] || 0,
                report["Nama Pekerjaan"]["Gardu (Unit)"] || 0,
                report["Nama Pekerjaan"]["JTR (Kms)"] || 0,
                report["Nama Pekerjaan"]["SR/APP (Pelanggan)"] || 0,
                tiangBeton["9 Meter (BTG)"] || 0,
                tiangBeton["12 Meter (BTG)"] || 0,
                tiangBeton["13 Meter (BTG)"] || 0,
                report["DESCRIPTION"], //.split("-")[0],
                "", //report["DESCRIPTION"].split("-")[1],
              ]);

              const excelEntry = {
                No: rowCounter - 1,
                Tanggal: entry.Date,
                createdBy,
                Jam: report["Jam"],
                "JTM (Kms)": report["Nama Pekerjaan"]["JTM (Kms)"] || 0,
                "Gardu (Unit)": report["Nama Pekerjaan"]["Gardu (Unit)"] || 0,
                "JTR (Kms)": report["Nama Pekerjaan"]["JTR (Kms)"] || 0,
                "SR/APP (Pelanggan)":
                  report["Nama Pekerjaan"]["SR/APP (Pelanggan)"] || 0,
                "9 Meter (BTG)": tiangBeton["9 Meter (BTG)"] || 0,
                "12 Meter (BTG)": tiangBeton["12 Meter (BTG)"] || 0,
                "13 Meter (BTG)": tiangBeton["13 Meter (BTG)"] || 0,
                Description: report["DESCRIPTION"], //.split("-")[0],
                Lokasi: "", //report["DESCRIPTION"].split("-")[1],
              };

              totalJTM += report["Nama Pekerjaan"]["JTM (Kms)"] || 0;
              totalGardu += report["Nama Pekerjaan"]["Gardu (Unit)"] || 0;
              totalJTR += report["Nama Pekerjaan"]["JTR (Kms)"] || 0;
              totalSRAPP += report["Nama Pekerjaan"]["SR/APP (Pelanggan)"] || 0;
              total9Meter += tiangBeton["9 Meter (BTG)"] || 0;
              total12Meter += tiangBeton["12 Meter (BTG)"] || 0;
              total13Meter += tiangBeton["13 Meter (BTG)"] || 0;

              excelData.push(excelEntry);
            });
          });
        });

        // Continue with the rest of your code...

        // Total row
        const totalRow = worksheet.addRow([
          "Total",
          "",
          "",
          "",
          totalJTM,
          totalGardu,
          totalJTR,
          totalSRAPP,
          total9Meter,
          total12Meter,
          total13Meter,
          "",
          "",
        ]);

        // Set styling untuk baris total
        worksheet.mergeCells(`A${totalRow.number}:B${totalRow.number}`);
        totalRow.font = { bold: true };
        totalRow.eachCell((cell) => {
          cell.alignment = { horizontal: "center" };
        });

        // Set column width
        worksheet.columns.forEach((column) => {
          column.eachCell({ includeEmpty: true }, (cell) => {
            cell.alignment = { wrapText: true };
          });
        });

        // Generate Excel buffer
        const excelBuffer = await workbook.xlsx.writeBuffer();

        // Create Blob and download
        const blob = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const currentDateWITA = dayjs().tz("Asia/Makassar").format("DDMMYYYY");
        const fileName = `reports tanggal ${currentDateWITA}.xlsx`;

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      }
    } catch (error) {
      enqueueSnackbar(`Belum ada laporan dari vendor`, {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 2000,
      });
      console.log(error);
    }
  };

  return (
    <button
      onClick={handleDownload}
      className="ml-2 p-2 mt-2 bg-transparent hover:bg-transparent border-none text-[green] border py-2 rounded flex items-center gap-3 text-[15px] "
    >
      <RiFileExcel2Line />
      <p>Download Excel</p>
    </button>
  );
}

export default DownloadExcel;
