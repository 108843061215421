import React, { useState, useEffect } from "react";
import axios from "axios";
import ModalUser from "./ModalUser";
import { useSnackbar } from "notistack";
import DataCardMonthly from "./DataCardMonthly";

export default function CardMonthly() {
  const [data, setData] = useState([]);
  const [activeDivision, setActiveDivision] = useState("JTM/JTR/Gardu");
  const [selectedDate, setSelectedDate] = useState(() => {
    const currentDate = new Date();
    currentDate.setDate(1);
    return currentDate;
  });
  const divisi = ["JTM/JTR/Gardu", "SR/APP", "Tiang Beton"];
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchData(selectedDate, activeDivision);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, activeDivision]);

  const handleDateChange = async (event) => {
    const selectedDateString = event.target.value;
    const selectedDate = new Date(selectedDateString);
    setSelectedDate(selectedDate);
  };

  const handleDivisionChange = (division) => {
    setActiveDivision(division);
  };

  const fetchData = async (reportCreatedAt, division) => {
    let formattedDate;
    try {
      formattedDate = reportCreatedAt.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      });
      const encodedDivision = encodeURIComponent(division);
      const response = await axios.get(
        `https://pln-backend.vercel.app/v1/api/created/${formattedDate}/division/${encodedDivision}`,
        {
          headers: { "Cache-Control": "no-store" },
        }
      );

      if (response.data.status_info) {
        setData(response.data.data);
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar(
        `Tidak ada laporan ${division} pada tanggal ${formattedDate}`,
        {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        }
      );
    }
  };

  const generateDateOptions = () => {
    const options = [];
    const currentYear = selectedDate.getFullYear();
    const currentMonth = selectedDate.getMonth();
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(currentYear, currentMonth, day);
      const formattedDate = date.toLocaleDateString("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric",
        timeZone: "Asia/Makassar",
      });

      options.push({
        label: formattedDate,
        value: date.toISOString(),
      });
    }

    return options;
  };

  return (
    <>
      <div className="md:pl-3 px-3 mt-20 md:mt-28 w-full md:ml-[20rem] ">
        <div className="flex flex-col md:flex-row gap-3 md:justify-between">
          <div className="flex gap-2 items-center md:justify-normal justify-between md:h-[24px]">
            <p className="font-bold">Laporan Bulanan</p>

            <div className="dropdown">
              <select
                className="select w-full max-w-xs bg-transparent"
                id="dateDropdown"
                value={selectedDate.toISOString()}
                onChange={handleDateChange}
              >
                {generateDateOptions().map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex gap-2 md:gap-5 ">
            <div className="flex gap-2 md:gap-5 overflow-y-auto">
              {divisi.map((division) => (
                <button
                  key={division}
                  className={`${
                    activeDivision === division
                      ? "valid:text-[#00AEEF] valid:font-[700]"
                      : ""
                  } bg-[#eeeeee] md:bg-transparent px-4 h-10 md:h-auto text-[14px] md:px-0 py-[1px] text-gray-400 rounded-full`}
                  onClick={() => handleDivisionChange(division)}
                >
                  <span>{division}</span>
                </button>
              ))}
            </div>

            <ModalUser />
          </div>
        </div>
        {data && data.length > 0 && <DataCardMonthly data={data} />}
      </div>
    </>
  );
}
