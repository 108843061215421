import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { FiEyeOff, FiEye } from "react-icons/fi";

export default function LoginForm() {
  const emailField = useRef("");
  const passwordField = useRef("");
  const rememberMeCheckbox = useRef(null);
  const router = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("rememberMe")) {
      const storedEmail = localStorage.getItem("email");

      if (storedEmail) {
        emailField.current.value = storedEmail;
      }
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    const isRememberMeChecked =
      rememberMeCheckbox.current && rememberMeCheckbox.current.checked;
    const loginPayload = {
      email: emailField.current.value,
      password: passwordField.current.value,
    };
    try {
      setIsLoading(true);
      const requestPayload = await axios.post(
        "https://pln-backend.vercel.app/v1/api/users/login",
        loginPayload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responsePayload = requestPayload.data;

      if (responsePayload.status_info) {
        const { token, getUser } = responsePayload.data;
        const userRole = getUser.role;
        const { email } = loginPayload;
        if (userRole === "supervisor") {
          router("/dashboard");

          if (isRememberMeChecked) {
            localStorage.setItem("email", email);
            localStorage.setItem("rememberMe", true);
          } else {
            // Clear localStorage if "Remember Me" is not checked
            localStorage.removeItem("email");
            localStorage.removeItem("password");
            localStorage.removeItem("rememberMe");
          }

          localStorage.setItem("token", token);
          Swal.fire({
            title: "Success!",
            text: `${responsePayload.message}`,
            icon: "success",
          });
        } else {
          Swal.fire({
            title: "Access Denied",
            text: `Only supervisors can access this page`,
            icon: "warning",
          });
        }
      } else {
        Swal.fire({
          title: "Warning",
          text: `${responsePayload.message}`,
          icon: "warning",
        });
      }
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        title: "Failed",
        text: `${error.response.data.message}`,
        icon: "error",
      });
    }
  };

  const handleShowPasswords = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <form action="" className="mt-3 w-[90%] " onSubmit={handleLogin}>
        <div className="mb-6 grid ">
          <input
            type="text"
            className=" bg-[transparent] border-b-2 border-[rgba(0,0,0,.25)] w-full p-2 focus:border-[none] outline-none"
            placeholder="Email"
            ref={emailField}
          />
          <span className="flex relative w-full">
            <input
              type={`${showPassword ? "text" : "password"}`}
              className=" bg-[transparent] border-b-2 border-[rgba(0,0,0,.25)] w-full p-2 focus:border-[none] outline-none "
              placeholder="Password"
              ref={passwordField}
            />
            <button
              onClick={handleShowPasswords}
              type="button"
              className="bg-transparent px-2 rounded-lg absolute right-0 bottom-0 top-0"
            >
              {showPassword ? <FiEye /> : <FiEyeOff />}
            </button>
          </span>
        </div>
        <div className="form-control mb-4">
          <label className="label cursor-pointer">
            <span className="label-text">Remember me</span>
            {rememberMeCheckbox && (
              <input
                type="checkbox"
                className="checkbox checkbox-sm border-2"
                ref={rememberMeCheckbox}
              />
            )}
          </label>
        </div>

        <div>
          <button
            className="w-full bg-[#292D32] py-2 rounded-lg mb-3 text-white"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? "Signing In..." : "Sign In"}
          </button>
        </div>
      </form>
    </>
  );
}
