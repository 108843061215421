import React from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
export default function Dashboard() {
  return (
    <>
      <Navbar />
      <div className="flex container mx-auto gap-10">
        <Sidebar />
      </div>
    </>
  );
}
