import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

export default function ModalDelete({ item }) {
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState(undefined);
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenModal = () => {
    setModal(!modal);
    fetchData(item.id);
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  const fetchData = async (id) => {
    try {
      const response = await axios.get(
        `https://pln-backend.vercel.app/v1/api/users/by/${id}`,
        {
          headers: {
            "Cache-Control": "no-store",
          },
        }
      );

      if (response.data.status_info) {
        if (item.status !== "alpha") {
          setData(response.data.data);
        }
      } else {
        console.error("Request success but status_info is false");
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      setIsLoading(true);
      const requestPayload = await axios.delete(
        `https://pln-backend.vercel.app/v1/api/users/delete/${id}`
      );

      const responsePayload = requestPayload.data;

      setIsLoading(false);
      if (responsePayload.status_info) {
        handleCloseModal();
        setIsLoading(false);
        Swal.fire({
          title: "Success",
          text: `${responsePayload.message}`,
          icon: "success",
        });
      } else {
        handleCloseModal();
        Swal.fire({
          title: "Warning",
          text: `${responsePayload.message}`,
          icon: "warning",
        });
      }
    } catch (error) {
      setIsLoading(false);
      handleCloseModal();
      Swal.fire({
        title: "Failed",
        text: `${error.response.data.message}`,
        icon: "error",
      });
    }
  };

  return (
    <>
      <button
        className="uppercase flex items-center justify-center py-1 rounded-lg  w-[2rem] h-[2rem] text-[0.75rem] md:w-[2.5rem] md:h-[2.5rem] md:text-[1.25rem] "
        style={{
          backgroundColor: "#EAD6FD",
          color: "#0C2240",
        }}
        onClick={handleOpenModal}
      >
        {item && item.name.slice(0, 1)}
      </button>

      <input
        type="checkbox"
        checked={modal}
        className="modal-toggle"
        onChange={handleOpenModal}
      />
      <div id="modal_item " className="modal overflow-auto " key={item.id}>
        <div className="modal-overlay flex justify-center">
          <div className="modal-box w-[98%] md:w-[700px] max-w-none mt-[30px] mb-[30px] max-h-none rounded-2xl pb-14">
            <button
              className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
              onClick={handleOpenModal}
            >
              ✕
            </button>

            <h3 className="font-black text-3xl py-5 text-center">
              Informasi Vendor
            </h3>
            <div className="bg-[#eff3f8] rounded-2xl flex items-center flex-col px-1 md:px-[3rem] py-5 gap-4">
              <div className="flex w-[90%] gap-3">
                <p className="w-1/5 py-1 text-[14px]">Nama Vendor</p>
                <p className="w-[80%] py-1 px-3 rounded-lg bg-white">
                  {item.name}
                </p>
              </div>
              <div className="flex w-[90%] gap-3">
                <p className="w-1/5 py-1 text-[14px]">Nama Direktur</p>
                <p className="w-[80%] py-1 px-3 rounded-lg bg-white">
                  {item.nip}
                </p>
              </div>
              <div className="flex w-[90%] gap-3">
                <p className="w-1/5 py-1 text-[14px]">Jenis KHS</p>
                <p className="w-[80%] py-1 px-3 rounded-lg bg-white">
                  {item.division}
                </p>
              </div>
              <div className="flex w-[90%] gap-3">
                <p className="w-1/5 py-1 text-[14px]">Email</p>
                <p className="w-[80%] py-1 px-3 rounded-lg bg-white">
                  {item.email}
                </p>
              </div>
              <div className="flex w-[90%] gap-3">
                <p className="w-1/5 py-1 text-[14px]">Nomor HP</p>
                <p className="w-[80%] py-1 px-3 rounded-lg bg-white">
                  {item.phone_number}
                </p>
              </div>
              <div className="flex w-[90%] gap-3">
                <p className="w-1/5 py-1 text-[14px]">Alamat</p>
                <p className="w-[80%] py-1 px-3 rounded-lg bg-white">
                  {item.address}
                </p>
              </div>

              <div className="flex w-[90%] gap-3 justify-end">
                <button
                  className="flex items-center w-[170px] justify-between bg-[#393E46] rounded-xl px-3 py-1 text-white"
                  onClick={() => handleDelete(item.id)}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <span className="flex items-center justify-center w-full">
                      <p>Loading...</p>
                    </span>
                  ) : (
                    <span className="flex items-center justify-center w-full">
                      <p>Hapus</p>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
