import { useState } from "react";
import { MdAccessTimeFilled, MdLocationOn } from "react-icons/md";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Swal from "sweetalert2";
import { FiSave } from "react-icons/fi";

export default function ModalMonthly({ item }) {
  const [data, setData] = useState(undefined);
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenModal = () => {
    setModal(!modal);
    fetchData(item.id);
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  const fetchData = async (monthlyRecapId) => {
    try {
      const response = await axios.get(
        `https://pln-backend.vercel.app/v2/api/monthly/search/${monthlyRecapId}`,
        {
          headers: {
            "Cache-Control": "no-store",
          },
        }
      );

      if (response.data.status_info) {
        if (item.status !== "alpha") {
          setData(response.data.data);
        }
      } else {
        console.error("Request success but status_info is false");
      }
    } catch (error) {
      console.log("error");
    }
  };

  const handleAccept = async (id) => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("token");
      const requestPayload = await axios.put(
        `https://pln-backend.vercel.app/v1/api/update/monthly/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responsePayload = requestPayload.data;

      setIsLoading(false);
      if (responsePayload.status_info) {
        handleCloseModal();
        setIsLoading(false);
        Swal.fire({
          title: "Accepted",
          text: `${responsePayload.message}`,
          icon: "success",
        });
      } else {
        handleCloseModal();
        Swal.fire({
          title: "Warning",
          text: `${responsePayload.message}`,
          icon: "warning",
        });
      }
    } catch (error) {
      setIsLoading(false);
      handleCloseModal();
      Swal.fire({
        title: "Failed",
        text: `${error.response.data.message}`,
        icon: "error",
      });
    }
  };

  const getColor = (status) => {
    if (status === "alpha") {
      return "#F97444";
    } else if (status === "waiting") {
      return "#0C2240";
    } else {
      return "#007C00";
    }
  };
  const getBackgroundColor = (status) => {
    if (status === "alpha") {
      return "#FEDDC7";
    } else if (status === "waiting") {
      return "#EAD6FD";
    } else {
      return "#5cb85c33";
    }
  };

  const updateColor = (status) => {
    return getColor(status);
  };
  const updateBackgroundColor = (status) => {
    return getBackgroundColor(status);
  };

  const carouselSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <button
        className="uppercase flex items-center justify-center py-1 rounded-lg w-[2rem] h-[2rem] text-[0.75rem]  md:w-[2.5rem] md:h-[2.5rem] md:text-[1.25rem] "
        style={{
          backgroundColor: updateBackgroundColor(item.status),
          color: updateColor(item.status),
        }}
        onClick={handleOpenModal}
      >
        {item.name ? item.name.slice(0, 1) : ""}
      </button>

      <input
        type="checkbox"
        checked={modal}
        className="modal-toggle"
        onChange={handleOpenModal}
      />
      <div id="modal_item " className="modal overflow-auto " key={item.id}>
        <div className="modal-overlay flex justify-center">
          <div className="modal-box w-[385px] md:w-[700px] max-w-none mt-[30px] mb-[30px] max-h-none rounded-2xl pb-14">
            <button
              className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
              onClick={handleOpenModal}
            >
              ✕
            </button>

            <h3 className="font-black text-3xl pb-5">Cek Laporan</h3>
            <div className="bg-[#eff3f8] rounded-2xl flex items-center flex-col px-1 md:px-[3rem] py-5 gap-4">
              <div className="flex w-[90%] gap-3">
                <p className="w-1/5 py-1 text-[14px]">Nama Vendor</p>
                <p className="w-[80%] py-1 px-3 rounded-lg bg-white">
                  {item.name}
                </p>
              </div>
              <div className="flex w-[90%] gap-3">
                <p className="w-1/5 py-1 text-[14px]">Nama Direktur</p>
                <p className="w-[80%] py-1 px-3 rounded-lg bg-white">
                  {item.nip}
                </p>
              </div>
              <div className="flex w-[90%] gap-3">
                <p className="w-1/5 py-1 text-[14px]">Jenis KHS</p>
                <p className="w-[80%] py-1 px-3 rounded-lg bg-white">
                  {item.division}
                </p>
              </div>

              <Slider className="w-[90%]" {...carouselSettings}>
                {Array.isArray(data) &&
                  data.map((value, index) => (
                    <div key={index}>
                      {value && value.JTM !== null && value.JTM !== "0" && (
                        <div className="flex w-full gap-3 mb-2 ">
                          <p className="w-1/5 py-1 text-[14px]">JTM</p>
                          <span className="w-[80%] py-1 px-3 rounded-lg bg-white  flex justify-between items-center">
                            <p>{value ? value.JTM : "JTM"}</p>
                            <p
                              style={{ borderLeft: "1px solid gray" }}
                              className="pl-2 h-5 leading-[18px] self-center"
                            >
                              Kms
                            </p>
                          </span>
                        </div>
                      )}
                      {value && value.JTR !== null && value.JTR !== "0" && (
                        <div className="flex w-full gap-3 mb-2 ">
                          <p className="w-1/5 py-1 text-[14px]">JTR</p>
                          <span className="w-[80%] py-1 px-3 rounded-lg bg-white flex justify-between items-center">
                            <p>{value ? value.JTR : "JTR"}</p>
                            <p
                              style={{ borderLeft: "1px solid gray" }}
                              className="pl-2 h-5 leading-[18px] self-center"
                            >
                              Kms
                            </p>
                          </span>
                        </div>
                      )}
                      {value && value.Gardu !== null && value.Gardu !== "0" && (
                        <div className="flex w-full gap-3 mb-2 ">
                          <p className="w-1/5 py-1 text-[14px]">Gardu</p>
                          <span className="w-[80%] py-1 px-3 rounded-lg bg-white  flex justify-between items-center">
                            <p>{value ? value.Gardu : "Gardu"}</p>
                            <p
                              style={{ borderLeft: "1px solid gray" }}
                              className="pl-2 h-5 leading-[18px] self-center"
                            >
                              Unit
                            </p>
                          </span>
                        </div>
                      )}
                      {value && value.SRAPP !== null && value.SRAPP !== "0" && (
                        <div className="flex w-full gap-3 mb-2 ">
                          <p className="w-1/5 py-1 text-[14px]">SR / APP</p>
                          <span className="w-[80%] py-1 px-3 rounded-lg bg-white flex justify-between items-center">
                            <p>{value ? value.SRAPP : "SR / APP"}</p>
                            <p
                              style={{ borderLeft: "1px solid gray" }}
                              className="pl-2 h-5 leading-[18px] self-center"
                            >
                              Pelanggan
                            </p>
                          </span>
                        </div>
                      )}
                      {value &&
                        (value.TB9 !== "0" ||
                          value.TB12 !== "0" ||
                          value.TB13 !== "0") && (
                          <div className="flex w-full gap-3 mb-2 ">
                            <p className="w-1/5 py-1 text-[14px]">
                              Tiang Beton
                            </p>
                            <div className="w-[80%] ">
                              {value && value.TB9 !== "0" && (
                                <span className="w-auto py-1 px-3 rounded-lg bg-white  flex justify-between items-center mb-3">
                                  <p>{value ? value.TB9 : "9 Meter"}</p>
                                  <span className="flex gap-2">
                                    <p>9 Meter</p>
                                    <p
                                      style={{ borderLeft: "1px solid gray" }}
                                      className="pl-2 h-5 leading-[18px] self-center"
                                    >
                                      BTG
                                    </p>
                                  </span>
                                </span>
                              )}
                              {value && value.TB12 !== "0" && (
                                <span className="w-auto py-1 px-3 rounded-lg bg-white  flex justify-between items-center mb-3">
                                  <p>{value ? value.TB12 : "12 Meter"}</p>
                                  <span className="flex gap-2">
                                    <p>12 Meter</p>
                                    <p
                                      style={{ borderLeft: "1px solid gray" }}
                                      className="pl-2 h-5 leading-[18px] self-center"
                                    >
                                      BTG
                                    </p>
                                  </span>
                                </span>
                              )}
                              {value && value.TB13 !== "0" && (
                                <span className="w-auto py-1 px-3 rounded-lg bg-white  flex justify-between items-center ">
                                  <p>{value ? value.TB13 : "13 Meter"}</p>
                                  <span className="flex gap-2">
                                    <p>13 Meter</p>
                                    <p
                                      style={{ borderLeft: "1px solid gray" }}
                                      className="pl-2 h-5 leading-[18px] self-center"
                                    >
                                      BTG
                                    </p>
                                  </span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}

                      <div className="flex w-full gap-3 mb-2 ">
                        <p className="w-1/5 py-1 text-[14px]">Foto</p>
                        <div className="w-[80%] py-3 px-3 rounded-lg bg-white">
                          {value ? (
                            <img
                              src={value && value.image}
                              alt=""
                              className="h-[110px] w-[250px] md:h-[200px] mb-4 md:w-[500px] rounded-md"
                            />
                          ) : (
                            <div className="rounded-md flex flex-col items-center justify-center">
                              <img
                                src="images/no-image.png"
                                alt=""
                                className="h-[110px] w-[250px] md:h-[200px] mb-4 md:w-[500px] rounded-md"
                              />
                              <p>No Image</p>
                            </div>
                          )}

                          <div className="flex items-center gap-4">
                            <span className="text-[10px] md:text-[14px] ">
                              <MdAccessTimeFilled />
                            </span>
                            <p className="text-[10px] md:text-[14px] ">
                              {value ? value.createdAt : "Time"}{" "}
                            </p>
                          </div>
                          {/* <div className="flex items-center gap-4">
                            <span className="text-[10px] md:text-[14px] ">
                              <MdLocationOn />
                            </span>
                            <p className="text-[10px] md:text-[14px] ">
                              {value
                                ? value.description.split("-")[0]
                                : "Location"}
                            
                            </p>
                          </div> */}
                        </div>
                      </div>
                      <div className="flex w-full gap-3 mb-2">
                        <p className="w-1/5 py-1 pr-6 text-[14px] ">
                          Deskripsi Kegiatan
                        </p>
                        <p className="w-[80%] py-3 px-5 rounded-lg bg-white h-[100px] md:h-[150px] text-justify overflow-y-auto">
                          {value
                            ? value.description // .split("-")[1]
                            : "No Descriptions"}
                          {/* diubah array 1 jadi 0 */}
                        </p>
                      </div>
                    </div>
                  ))}
              </Slider>

              <div className={`flex w-[90%] gap-3 justify-end`}>
                <button
                  className={`flex items-center w-[170px] justify-between bg-[#393E46] rounded-xl px-3 py-1 text-white ${
                    item.status !== "waiting" ? "hidden" : "block"
                  }`}
                  onClick={() => handleAccept(item.id)}
                  disabled={isLoading || item.status === "alpha"}
                >
                  {isLoading ? (
                    <span className="flex items-center justify-center w-full">
                      <p>Loading...</p>
                    </span>
                  ) : (
                    <span className="flex items-center justify-between w-full">
                      <FiSave />
                      <p className="font-thin">Terima</p>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
