import { useState, useEffect, useRef } from "react";
import axios from "axios";
import DataCard from "./DataCard";
import ModalUser from "./ModalUser";
import { useSnackbar } from "notistack";

export default function CardDaily() {
  const [data, setData] = useState([]);
  const [activeDivision, setActiveDivision] = useState("JTM/JTR/Gardu");
  const divisi = ["JTM/JTR/Gardu", "SR/APP", "Tiang Beton"];
  const { enqueueSnackbar } = useSnackbar();
  const isSnackbarDisplayedRef = useRef(false);

  const handleDivisionClick = async (division) => {
    try {
      setActiveDivision(division);
      const encodedDivision = encodeURIComponent(division);
      const requestPayload = await axios.get(
        `https://pln-backend.vercel.app/v1/api/users/${encodedDivision}`,
        {
          headers: { "Cache-Control": "no-store" },
        }
      );
      const response = requestPayload.data;
      console.log(response);
      if (response.status_info) {
        setData(response.data);
        isSnackbarDisplayedRef.current = false;
      }
    } catch (error) {
      if (!isSnackbarDisplayedRef.current) {
        enqueueSnackbar(`Tidak ada vendor pada KHS ${division}`, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
        isSnackbarDisplayedRef.current = true;
      }
    }
  };

  const fetchDataPeriodically = async () => {
    try {
      const encodedDivision = encodeURIComponent(activeDivision);
      const requestPayload = await axios.get(
        `https://pln-backend.vercel.app/v1/api/users/${encodedDivision}`,
        {
          headers: { "Cache-Control": "no-store" },
        }
      );
      const response = requestPayload.data;
      if (response.status_info) {
        setData(response.data);
      }
    } catch (error) {
      console.log("error");
    }
  };

  useEffect(() => {
    handleDivisionClick(activeDivision);
    const intervalId = setInterval(() => {
      fetchDataPeriodically();
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDivision]);

  return (
    <>
      <div className="md:pl-3 px-3 mt-20 md:mt-28 w-full md:ml-[20rem] ">
        <div className="flex flex-col md:flex-row gap-3 md:justify-between md:h-[24px]">
          <p className="font-bold">Laporan Harian</p>

          <div className="flex gap-2 md:gap-5 ">
            <div className="flex gap-2 md:gap-5 overflow-y-auto">
              {divisi.map((division) => (
                <button
                  key={division}
                  className={`${
                    activeDivision === division
                      ? "valid:text-[#00AEEF] valid:font-[700]"
                      : ""
                  } bg-[#eeeeee] md:bg-transparent px-4 h-10 md:h-auto text-[14px] md:px-0 py-[1px] text-gray-400 rounded-full`}
                  onClick={() => handleDivisionClick(division)}
                >
                  <span>{division}</span>
                </button>
              ))}
            </div>

            <ModalUser />
          </div>
        </div>
        {data && data.length > 0 && <DataCard data={data} />}
      </div>
    </>
  );
}
