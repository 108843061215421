import { useState, useEffect } from "react";
import axios from "axios";
import DataCardUser from "./DataCardUser";
import ModalUser from "./ModalUser";

export default function CardUsers() {
  const [data, setData] = useState([]);

  const fetchDataPeriodically = async () => {
    try {
      const requestPayload = await axios.get(
        `https://pln-backend.vercel.app/v1/api/users`,
        {
          headers: { "Cache-Control": "no-store" },
        }
      );
      const response = requestPayload.data;
      if (response.status_info) {
        setData(response.data.users);
      }
    } catch (error) {
      console.log("error");
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchDataPeriodically();
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="md:pl-3 px-3 mt-20 md:mt-28 w-full md:ml-[20rem] ">
        <div className="flex flex-row gap-3 justify-between md:h-[24px]">
          <p className="font-bold">Daftar Vendor</p>
          <ModalUser />
        </div>
        {data && data.length > 0 && <DataCardUser data={data} />}
      </div>
    </>
  );
}
