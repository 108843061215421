import React from "react";
import LoginForm from "../components/LoginForm";

export default function Login() {
  return (
    <>
      <div className=" bg-[#00AFEF] md:px-20 h-screen md:py-0 py-[126px] px-5 flex justify-center">
        <div className="shadow-lg shadow-[#79CBD7] md:h-[90%] self-center justify-center rounded-[20px] bg-[#FFF200] md:flex gap-3 md:w-[80%] w-[90%] absolute md:static">
          <div className="p-12 sm:p-10 md:pl-12 flex flex-col items-center justify-center md:w-[50%] ">
            <p className="text-5xl sm:text-5xl md:text-6xl px-5 font-extrabold text-center leading-[3rem] md:leading-[4rem] ">
              Hallo, Tivocy
            </p>
            <p className="font-bold px-4 text-center mt-3 pb-3 md:pb-0">
              Masuk ke Tivocy sekarang juga
            </p>
            <LoginForm />
          </div>
          <img
            src="images/login.png"
            alt=""
            className="w-auto hidden md:block md:w-[50%] "
          />
        </div>
      </div>
    </>
  );
}
