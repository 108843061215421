import { useState, useEffect } from "react";
import axios from "axios";
import DataCardWeekly from "./DataCardWeekly";
import ModalUser from "./ModalUser";
import { useSnackbar } from "notistack";

export default function CardWeekly() {
  const [data, setData] = useState([]);
  const [activeDivision, setActiveDivision] = useState("JTM/JTR/Gardu");
  const [selectedDay, setSelectedDay] = useState("Monday");
  const divisi = ["JTM/JTR/Gardu", "SR/APP", "Tiang Beton"];
  const { enqueueSnackbar } = useSnackbar();

  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const handleDateChange = async (day) => {
    try {
      setSelectedDay(day);
      const encodedDivision = encodeURIComponent(activeDivision);
      const response = await axios.get(
        `https://pln-backend.vercel.app/v1/api/day/${day}/division/${encodedDivision}`,
        {
          headers: { "Cache-Control": "no-store" },
        }
      );

      if (response.data.status_info) {
        setData(response.data.data);
      }
    } catch (error) {
      enqueueSnackbar(
        `Tidak ada laporan ${activeDivision} pada hari ${
          day === "Sunday"
            ? "Minggu"
            : day === "Monday"
            ? "Senin"
            : day === "Tuesday"
            ? "Selasa"
            : day === "Wednesday"
            ? "Rabu"
            : day === "Thursday"
            ? "Kamis"
            : day === "Friday"
            ? "Jumat"
            : day === "Saturday"
            ? "Sabtu"
            : ""
        }`,
        {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        }
      );
    }
  };

  useEffect(() => {
    handleDateChange(selectedDay);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDay, activeDivision]);

  return (
    <>
      <div className="md:pl-3 px-3 mt-20 md:mt-28 w-full md:ml-[20rem]">
        <div className="flex flex-col md:flex-row gap-3 md:justify-between">
          <div className="flex gap-2 items-center md:justify-normal justify-between md:h-[24px]">
            <p className="font-bold">Laporan Mingguan</p>
            <div className="dropdown">
              <select
                className="select w-full max-w-xs bg-transparent"
                id="dateDropdown"
                value={selectedDay}
                onChange={(e) => handleDateChange(e.target.value)}
              >
                {days.map((day) => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex gap-2 md:gap-5 ">
            <div className="flex gap-2 md:gap-5 overflow-y-auto">
              {divisi.map((division) => (
                <button
                  key={division}
                  className={`${
                    activeDivision === division
                      ? "valid:text-[#00AEEF] valid:font-[700]"
                      : ""
                  } bg-[#eeeeee] md:bg-transparent px-4 h-10 md:h-auto text-[14px] md:px-0 py-[1px] text-gray-400 rounded-full`}
                  onClick={() => setActiveDivision(division)}
                >
                  <span>{division}</span>
                </button>
              ))}
            </div>

            <ModalUser />
          </div>
        </div>
        {data && data.length > 0 && <DataCardWeekly data={data} />}
      </div>
    </>
  );
}
